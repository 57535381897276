const BOOL_VALUES = ["directioncode", "leftvoicemail", "isworkflowcreated", "isregularactivity", "ismapiprivate"];
import { isString, isNull, isEmpty } from "lodash";
export const isValidJSON = (text) => {
  if (typeof text !== "string") {
    return false;
  }
  try {
    var json = JSON.parse(text);
    return typeof json === "object";
  } catch (error) {
    return false;
  }
};
export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
export function getClientDomain({ clientDomains, origin }) {
  const domains = clientDomains.split(",");

  if (origin) {
    for (const domain of domains) {
      if (origin.includes(domain)) {
        return domain;
      }
    }
  }

  return domains[0];
}
export function replaceQueryStringVariables({ queryString, stepResponseName, stepResponseData, appid, contact }) {
  for (const param in queryString) {
    if (queryString[param].startsWith("{{") && queryString[param].endsWith("}}")) {
      if (queryString[param].includes(stepResponseName)) {
        const keyToSearchFromStepResponse = queryString[param].split(".")[1].slice(0, -2);
        queryString[param] = stepResponseData[keyToSearchFromStepResponse];
      } else if (queryString[param].includes("appid")) {
        queryString[param] = appid;
      } else {
        const strippedCurleybraces = queryString[param].replace(/[{}]/g, "");
        const rootObjectKey = strippedCurleybraces?.split(".")[0];

        let isRootObjNested = strippedCurleybraces?.split(".").length > 2;

        const rootObjValue = isString(contact[rootObjectKey]) ? JSON.parse(contact[rootObjectKey]) : contact[rootObjectKey];
        const objectToMap = isRootObjNested ? rootObjValue[strippedCurleybraces?.split(".")[1]] : rootObjValue;
        console.log(objectToMap, "objtomap");
        if (!!objectToMap && !isEmpty(objectToMap)) {
          const keys = Object.keys(objectToMap);

          for (const property of keys) {
            if (queryString[param].includes(property)) {
              queryString[param] = objectToMap[property];
            }
          }
        }
      }
    }
  }
  console.log(queryString, "OPEN URL FOR AGENT QUERYSTRING VARIABLES");
  return queryString;
}
export function replaceODataVariables({ queryString, stepResponseName, stepResponseData, contact }) {
  if (queryString.startsWith("(") && queryString.includes(")") && queryString.includes(stepResponseName)) {
    let keyValueToUseFromStepResponse = queryString.includes("?")
      ? queryString?.split("?")[0].split(".")[1].slice(0, -1)
      : queryString?.split(".")[1].slice(0, -1);
    let keyValueToBeReplaced = `${stepResponseName}.${keyValueToUseFromStepResponse}`;

    queryString = replaceAll({
      string: queryString,
      values: { [keyValueToBeReplaced]: stepResponseData[keyValueToUseFromStepResponse] },
    });
  } else {
    queryString = replaceAll({
      string: queryString,
      values: contact,
    });
  }
  return queryString;
}
export function replaceRequestBodyVariables({ requestBody, stepResponseName, stepResponseData, contact }) {
  for (const param in requestBody) {
    if (requestBody[param].startsWith("{{") && requestBody[param].endsWith("}}")) {
      if (requestBody[param].includes(stepResponseName)) {
        const keyToSearchFromStepResponse = requestBody[param].split(".")[1].slice(0, -2);

        requestBody[param] = stepResponseData[keyToSearchFromStepResponse];
      } else if (
        checkIfRequestBodyVariablesContainsAnObjectKeyValue({ string: requestBody[param] }) ||
        (requestBody[param].includes(".") && !requestBody[param].includes("STEP"))
      ) {
        const strippedCurleybraces = requestBody[param].replace(/[{}]/g, "");
        const rootObjectKey = strippedCurleybraces?.split(".")[0];

        let isRootObjNested = strippedCurleybraces?.split(".").length > 2;

        const rootObjValue = isString(contact[rootObjectKey]) ? JSON.parse(contact[rootObjectKey]) : contact[rootObjectKey];
        const objectToMap = isRootObjNested ? rootObjValue[strippedCurleybraces?.split(".")[1]] : rootObjValue;

        if (!!objectToMap && !isEmpty(objectToMap)) {
          const keys = Object.keys(objectToMap);

          for (const property of keys) {
            if (requestBody[param].includes(property)) {
              requestBody[param] = objectToMap[property];
            }
          }
        }
      } else {
        for (const attribute in contact) {
          if (requestBody[param].includes(attribute)) {
            requestBody[param] = contact[attribute];
          }
        }
      }
    } else if (requestBody[param]?.startsWith("[") && requestBody[param]?.endsWith("]")) {
      //eslint-disable-next-line
      const strippedValue = requestBody[param].replace(/[\[\]']+/g, "");

      if (BOOL_VALUES.includes(param)) {
        requestBody[param] = Boolean(parseInt(strippedValue));
      } else {
        requestBody[param] = parseInt(strippedValue);
      }
    } else {
      if (requestBody[param].includes(stepResponseName)) {
        const keyToSearchFromStepResponse = requestBody[param].split(".")[1].slice(0, -1);
        contact[`${stepResponseName}.${keyToSearchFromStepResponse}`] = stepResponseData[keyToSearchFromStepResponse];
      }
      requestBody[param] = replaceAll({
        string: requestBody[param],
        values: contact,
      });
    }
  }
  return requestBody;
}
export const checkIfRequestBodyVariablesContainsAnObjectKeyValue = ({ string }) => {
  if (string.match(/\..*\./) && !string.match(/\s/)) {
    return true;
  } else {
    return false;
  }
};
export const replaceAll = ({ string, values }) => {
  let matched;
  try {
    matched = new RegExp(Object.keys(values).join("|"), "gi");
  } catch (error) {
    return "";
  }
  return string.replace(matched, (match) => {
    return values[match];
  });
};
